// extracted by mini-css-extract-plugin
export var form_control = "modal-vr-module--form_control--dbaf9";
export var initial = "modal-vr-module--initial--b8731";
export var initial_intro_title = "modal-vr-module--initial_intro_title--fa63b";
export var initial_tc = "modal-vr-module--initial_tc--b2219";
export var invalid_feedback = "modal-vr-module--invalid_feedback--be844";
export var invalid_feedback_input = "modal-vr-module--invalid_feedback_input--7bb49";
export var mfSub = "modal-vr-module--mfSub--1c51b";
export var processing = "modal-vr-module--processing--f33cc";
export var processingBtn = "modal-vr-module--processingBtn--d0818";
export var processing_intro_title = "modal-vr-module--processing_intro_title--92230";
export var processing_tc = "modal-vr-module--processing_tc--b1563";
export var send_icon = "modal-vr-module--send_icon--7a2b9";
export var success = "modal-vr-module--success--3ff7e";
export var successBtn = "modal-vr-module--successBtn--a11e6";
export var success_intro_title = "modal-vr-module--success_intro_title--2fcbb";
export var success_message = "modal-vr-module--success_message--97203";
export var success_message_init = "modal-vr-module--success_message_init--a5e0f";
export var ts_cs = "modal-vr-module--ts_cs--db887";