import React, { useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { useForm } from "react-hook-form"

import * as style from "./modal-vr.module.css"

const VrOneModal = props => {
  const [verified, setVerified] = useState(false)
  const recaptchaRef = React.createRef()
  const [recap, setRecap] = useState("")

  const onChange = value => {
    setRecap(value)
    setVerified(prevVerified => !prevVerified)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  // set error classes

  const classInit = `form-control mt-5 ${style.form_control}`

  const classError = `${classInit} ${style.invalid_feedback_input}`

  // set form state

  const [submitStatus, setSubmitStatus] = useState("initial")

  // set success function

  const subSuccess = () => {
    // set small delay to allow smoother animation
    setTimeout(function () {
      setSubmitStatus("success")
    }, 1000)
  }

  // handle submission

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const onSubmit = data => {
    if (verified) {
      setSubmitStatus("processing")

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "vrDemoApplication",
          enqEmail: data.enqEmail,
          enqName: data.enqName,
          enqCompany: data.enqCompany,
          enqTitle: data.enqTitle,
          enqMiscData: data.enqMIscData,
          "g-recaptcha-response": recap,
        }),
      })
        .then(() => subSuccess())
        .catch(error => setSubmitStatus("initial"))
    } else {
      console.log("Google reCaptcha not set")
    }
  }

  // generate form

  return (
    <>
      <div className="container gx-0">
        <div
          className="row justify-content-center p-2 p-lg-4"
          style={{ backgroundColor: "#141313" }}
        >
          <div className="col-12">
            <div className="d-flex justify-content-between py-3">
              <span></span>
              <img
                className="move-in-left mw-100"
                src="/assets/img/Waracle-Icon.svg"
                alt="waracle"
                style={{ width: "40px" }}
              />
              <img
                className="close_button"
                src="/assets/img/close-24-px-2-white.svg"
                alt=""
                onClick={props.close}
              />
            </div>
            <h2 className="h3 mb-0 mt-3 mt-2 mt-lg-0 mx-lg-5 text-center px-3 px-lg-5 color-white">
              Experience the power of VR with Waracle's immersive workshop
            </h2>
            <p className="mt-3 mx-5 text-center px-5 color-white d-none d-lg-block">
              For this free, but limited availability workshop, we'll send you
              state-of-the-art VR headsets pre-configured for the event and host
              you through the whole experience.
            </p>

            <form
              onSubmit={handleSubmit(onSubmit)}
              name="vrDemoApplication"
              method="POST"
              data-netlify="true"
              netlify-honeypot="enqMIscData"
              data-netlify-recaptcha="true"
              action="/thank-you"
            >
              <div
                className={
                  submitStatus === "success"
                    ? `${style.initial} ${style.processing} ${style.success}`
                    : "" || submitStatus === "processing"
                    ? `${style.initial} ${style.processing} `
                    : "" || submitStatus === "initial"
                    ? style.initial
                    : style.initial
                }
                style={{ padding: "0 20px" }}
              >
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <input
                      {...register("enqName", { required: true })}
                      type="text"
                      placeholder="Full Name*"
                      aria-label=""
                      className={
                        errors.enqName ? `${classError}` : `${classInit}`
                      }
                    />
                    {errors.enqName && (
                      <span
                        className={`${style.invalid_feedback} d-block pt-1 form-text`}
                        style={{ position: "absolute" }}
                      >
                        Required
                      </span>
                    )}
                  </div>

                  <div className="col-12 col-lg-6">
                    <input
                      {...register("enqEmail", {
                        required: true,
                        pattern: /^\S+@\S+$/i,
                      })}
                      type="text"
                      placeholder="Email Address*"
                      aria-label=""
                      className={
                        errors.enqEmail ? `${classError}` : `${classInit}`
                      }
                    />
                    {errors.enqEmail && (
                      <span
                        className={`${style.invalid_feedback} d-block pt-1 form-text`}
                        style={{ position: "absolute" }}
                      >
                        Valid email address required
                      </span>
                    )}
                  </div>

                  <div className="col-12 col-lg-6">
                    <input
                      {...register("enqCompany", { required: true })}
                      type="text"
                      placeholder="Company Name*"
                      aria-label=""
                      className={
                        errors.enqCompany ? `${classError}` : `${classInit}`
                      }
                    />
                    {errors.enqCompany && (
                      <span
                        className={`${style.invalid_feedback} d-block pt-1 form-text`}
                        style={{ position: "absolute" }}
                      >
                        Required
                      </span>
                    )}
                  </div>

                  <div className="col-12 col-lg-6">
                    <input
                      {...register("enqTitle", { required: true })}
                      type="text"
                      placeholder="Job Title*"
                      aria-label=""
                      className={
                        errors.enqTitle ? `${classError}` : `${classInit}`
                      }
                    />
                    {errors.enqTitle && (
                      <span
                        className={`${style.invalid_feedback} d-block pt-1 form-text`}
                        style={{ position: "absolute" }}
                      >
                        Required
                      </span>
                    )}
                  </div>
                </div>

                <div className="mt-5 mb-3 position-relative">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY}
                    size="normal"
                    theme="dark"
                    onChange={onChange}
                  />

                  {!verified && errors.enqEmail && (
                    <span
                      className={`${style.invalid_feedback} position-absolute d-block pt-1 form-text`}
                      style={{
                        top: "75px",
                        left: "0",
                      }}
                    >
                      Please confirm you are not a robot.
                    </span>
                  )}
                </div>
              </div>

              <div className="d-none">
                <input {...register("enqMIscData", { required: false })} />
              </div>

              <div style={{ padding: "0 20px" }}>
                <button
                  type="submit"
                  className={
                    submitStatus === "success"
                      ? `${style.mfSub} ${style.processingBtn}  ${style.successBtn}`
                      : "" || submitStatus === "processing"
                      ? `${style.mfSub} ${style.processingBtn}`
                      : "" || submitStatus === "initial"
                      ? `${style.mfSub} ${style.send_icon} mt-5`
                      : `${style.mfSub} ${style.send_icon} mt-5`
                  }
                >
                  {submitStatus === "success" ? (
                    <span>
                      <img
                        src="/assets/img/icon_success.svg"
                        alt=""
                        style={{ width: "32px" }}
                      />
                    </span>
                  ) : "" || submitStatus === "processing" ? (
                    <span>
                      <img
                        src="/assets/img/submit_white_alt.svg"
                        alt=""
                        style={{ width: "24px" }}
                      />
                    </span>
                  ) : "" || submitStatus === "initial" ? (
                    <span>
                      <img
                        src="/assets/img/submit_white_alt.svg"
                        alt=""
                        style={{ width: "24px", marginRight: "10px" }}
                      />{" "}
                      Send application
                    </span>
                  ) : (
                    <span>
                      <img
                        src="/assets/img/submit_white_alt.svg"
                        alt=""
                        style={{ width: "24px", marginRight: "10px" }}
                      />{" "}
                      Send application
                    </span>
                  )}
                </button>

                <div
                  className={
                    submitStatus === "success"
                      ? `${style.success_message_init} ${style.success_message} text-center`
                      : `${style.success_message_init}`
                  }
                >
                  <h3 className="text-center mt-4 ga_form_submit">
                    Thanks you, Your request has been sent!
                  </h3>
                  <p className="text-center mb-4">
                    We've sent on the request to our immersive experiences team
                    and we'll be in touch shortly to discuss the event.
                  </p>
                </div>

                <div
                  className={
                    submitStatus === "success"
                      ? `${style.ts_cs} ${style.initial_tc} ${style.processing_tc} ${style.success}`
                      : "" || submitStatus === "processing"
                      ? `${style.ts_cs} ${style.initial_tc} ${style.processing_tc}`
                      : "" || submitStatus === "initial"
                      ? `${style.ts_cs} ${style.initial_tc} mt-2 mb-4`
                      : `${style.ts_cs} ${style.initial_tc} mt-2 mb-4`
                  }
                >
                  By submitting this form you agree to the storing and
                  processing of your personal data by Waracle as described in
                  the 'Who we share this data with' section of the{" "}
                  <a
                    href="/privacy-policy"
                    target="_blank"
                    style={{ color: "white" }}
                  >
                    {" "}
                    Privacy policy
                  </a>
                  .
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default VrOneModal
