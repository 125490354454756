import React, { useRef, useState, useEffect } from "react"
import { Modal } from "react-bootstrap"
import { graphql } from "gatsby"
import Layout from "./Layout"
import { seoHead, isSafariLess16 } from "../components/utils.js"
import VrOneModal from "../components/landing-pages/modal-vr"
import * as style from "./page-template-vr-one.module.css"

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      page(id: $id) {
        id
        title
        content
        uri

        featuredImage {
          node {
            altText
            mimeType
            mediaDetails {
              file
            }
          }
        }
        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphUrl
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphImage {
            mimeType
            mediaDetails {
              file
            }
          }
        }
      }
    }
  }
`

const VrV1Template = () => {
  const [showVrModal, setShowVrModal] = useState(false)

  const handleCloseVrModal = () => setShowVrModal(false)
  const handleShowVrModal = () => setShowVrModal(true)

  const [progress, setProgress] = useState(0)
  const videoRef = useRef()
  const [currentTime, setCurrentTime] = useState(0)
  const [videoTime, setVideoTime] = useState(0)

  const callbackModal = () => {
    setShowVrModal(false)
    setPlayVideo(false)
  }

  // load video into dom on click

  const [playVideo, setPlayVideo] = useState(false)

  function startVideo() {
    setPlayVideo(true)
    try {
      var hideRowNavOpen = document.getElementById("hide-row-when-nav-open")
      hideRowNavOpen.classList.add("hide-down")
    } catch (err) {}
  }

  // pop modal on 2nd video complete

  function myHandler() {
    console.log("video complete")
    setShowVrModal(true)
    try {
      var hideRowNavOpen = document.getElementById("hide-row-when-nav-open")
      hideRowNavOpen.classList.remove("hide-down")
    } catch (err) {}
  }

  // get window width for video selection

  const hasWindow = typeof window !== "undefined"

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null
    return {
      width,
    }
  }

  const [videoSize, setVideoSize] = useState("mobile")

  useEffect(() => {
    let theVideoWidth = getWindowDimensions()
    if (theVideoWidth.width > 800) {
      setVideoSize("desktop")
    } else {
      setVideoSize("mobile")
    }
    try {
      var vid = document.getElementById("vrMainOverlay")
      setVideoTime(vid.duration)
    } catch (err) {}
  })

  // get the video progress value
  useEffect(() => {
    window.setInterval(function () {
      setCurrentTime(videoRef.current?.currentTime)
      let tempTime = (videoRef.current?.currentTime / videoTime) * 100
      if (!isNaN(tempTime)) {
        setProgress(tempTime)
      }
    }, 1000)
  })

  const isSafariV16 = isSafariLess16()

  return (
    <>
      <Layout>
        <div
          style={{
            height: "100vh",
            backgroundColor: "black",
          }}
          className={style.video_container}
        >
          {/* Desktop videos */}

          {videoSize === "desktop" && (
            <video
              id="vrInitOverlay"
              muted
              autoPlay
              loop
              playsInline
              style={{
                position: "fixed",
                minWidth: "100%",
                minHeight: "100vh",
                zIndex: "0",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <source
                src="https://res.cloudinary.com/dvmdv4ttu/video/upload/v1668511165/video/Starting-Dots_eajcz6.mp4"
                type="video/mp4"
              />
            </video>
          )}

          {playVideo && videoSize === "desktop" && (
            <>
              <video
                id="vrMainOverlay"
                autoPlay
                muted
                playsInline
                onEnded={myHandler}
                ref={videoRef}
                style={{
                  position: "fixed",
                  minWidth: "100%",
                  minHeight: "100vh",
                  zIndex: "2",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <source
                  src="https://res.cloudinary.com/dvmdv4ttu/video/upload/v1668512671/video/Headset-Animation-no-delay-dt_l4dphz.mp4"
                  type="video/mp4"
                />
              </video>
              <div
                className="progress"
                style={{
                  width: "80%",
                  height: "4px",
                  bottom: "25px",
                  left: "10%",
                  zIndex: "5",
                  position: "fixed",
                }}
              >
                <progress
                  id="progress"
                  value={progress}
                  max="100"
                  style={{ width: "100%" }}
                >
                  <span id="progress-bar"></span>
                </progress>
              </div>
            </>
          )}

          {/* Mobile videos */}

          {videoSize === "mobile" && (
            <video
              id="vrInitOverlay"
              muted
              autoPlay
              loop
              playsInline
              style={{
                position: "fixed",
                minWidth: "100%",
                minHeight: "100vh",
                zIndex: "0",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <source
                src="https://res.cloudinary.com/dvmdv4ttu/video/upload/v1668511166/video/Mobile-Starting-Dots_puhhkz.mp4"
                type="video/mp4"
              />
            </video>
          )}

          {playVideo && videoSize === "mobile" && (
            <>
              <video
                id="vrMainOverlay"
                autoPlay
                muted
                playsInline
                onEnded={myHandler}
                ref={videoRef}
                style={{
                  position: "fixed",
                  minWidth: "100%",
                  minHeight: "100vh",
                  zIndex: "2",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <source
                  src="https://res.cloudinary.com/dvmdv4ttu/video/upload/v1668511167/video/Mobile-Headset-Animation_ox70gt.mp4"
                  type="video/mp4"
                />
              </video>
              <div
                className="progress"
                style={{
                  width: "80%",
                  height: "4px",
                  bottom: "25px",
                  left: "10%",
                  zIndex: "5",
                  position: "fixed",
                }}
              >
                <progress
                  id="progress"
                  value={progress}
                  max="100"
                  style={{ width: "100%" }}
                >
                  <span id="progress-bar"></span>
                </progress>
              </div>
            </>
          )}

          <div
            id="hide-row-when-nav-open"
            className="text-center w-100 animated-headline"
            style={{
              position: "absolute",
              top: "30%",
              zIndex: "3",
            }}
          >
            <div className="animated-headline-wrap px-3 px-lg-0">
              <h1 className="new_style color-white mb-4 mb-lg-0">
                <span
                  className={`new_style_addit ${
                    isSafariV16 ? "safari_16" : ""
                  }`}
                  style={{ fontWeight: "bold" }}
                >
                  Experience the power of VR{" "}
                  <br className="display-none; display-lg-block" />
                  with Waracle's immersive workshop
                </span>
              </h1>
            </div>

            <button
              className={`${style.mfSub} header-fade-in mt-4`}
              onClick={startVideo}
            >
              <span>
                <img
                  src="/assets/img/submit_white_alt.svg"
                  alt=""
                  style={{
                    width: "24px",
                    marginRight: "10px",
                  }}
                />{" "}
                Show me
              </span>
            </button>

            <span className="header-fade-in color-white mt-2 d-block">or</span>
            <span
              className={`header-fade-in color-white mt-1 d-block ${style.apply_link}`}
              style={{ textDecoration: "underline" }}
              onClick={handleShowVrModal}
              role="link"
              tabIndex={0}
            >
              Apply now!
            </span>
          </div>
        </div>

        <Modal
          show={showVrModal}
          onHide={handleCloseVrModal}
          size="lg"
          backdrop="static"
          keyboard={false}
          centered
        >
          <VrOneModal close={callbackModal} />
        </Modal>
      </Layout>
    </>
  )
}

export default VrV1Template

// Metadata in head from gatsby ^v4.19.0
// replaces helmet which wasn't generating the tags statically

export const Head = ({ data }) => {
  const page = data.wpgraphql.page
  const seoTags = seoHead(page.seo, page.uri)
  return seoTags
}
